<script lang="ts">
	import Button from '$lib/components/Button.svelte';
	import Header from '$lib/components/header/Header.svelte';
	import Modal from '$lib/components/Modal.svelte';
	import Register from '$lib/components/auth/Register.svelte';
	import { openModal } from 'svelte-modals';
	import { apiFetch } from '$lib/utils/fetch';
	import { useQueryClient, createQuery } from '@tanstack/svelte-query';
	import Server, { type TServer } from '$lib/components/server.svelte';
	import { authenticated, user } from '$lib/stores/user';
	import User from '$lib/components/player/User.svelte';
	import PlayerCard from '$lib/components/player/PlayerCard.svelte';

	const client = useQueryClient();

	$: servers = createQuery<{ servers: TServer[] }>({
    queryKey: ['servers'],
    queryFn: async () => 
		await apiFetch("/servers",{
			method: 'GET'
		})
			.then((fetchData) => {
				return { servers: fetchData.data };
			}),
    refetchInterval: 10000,
  })



	function handleOpenRegisterModal() {
		openModal(Modal, { component: Register, props: {} });
	}
</script>

<section>
	<Header class="header-front-page backdrop-blur-md" />

	<div class="section register-section">
		<div class="diffuser">
			<div class="cas">
				<p>Välkommen till Playpark</p>
				{#if $authenticated}
					<p class="text-small">Hej, {$user?.username} </p>
				{:else}
				<span class="appear-slow">
				<p class="text-small">Bli medlem idag!</p>
				<Button on:click={handleOpenRegisterModal}>Skapa ett konto gratis</Button>
				</span>
				{/if}
			</div>
		</div>
	</div>

	<div class="section" class:servers={$servers.isSuccess}>
		{#if $servers.isSuccess}
		{#each $servers.data.servers as server, index}
				{#if index < 3}
					<Server server={server}/>
				{/if}
			{/each}
		{/if}
	</div>
</section>

<style lang="scss">
	.register-section {
		margin-top: -80px;
		background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%),
			url(https://i.pinimg.com/originals/84/8a/1f/848a1f92871765754d455cd9babddf73.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		
	}

	.appear-slow {
		animation: fadeInSlideUp 2s;
	}

	/* Add keyframes for fade-in and slide-up animations */
@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to the .register-section class */
.cas {
	animation: fadeInSlideUp 1s ease-out forwards;
}

.servers {
	animation: fadeInSlideUp 1s ease-out forwards;
}
  
	.diffuser {
		display: flex;
		align-items: flex-end;
		padding: 64px;
		padding-top: 300px;
		width: 100%;
		background: rgb(0 0 0 / 50%);
	}
	.cas {
		padding: 64px;
		color: #fff;
	}

	.cas p {
		font-weight: bold;
		font-size: 50px;
		line-height: 40px;
	}

	.cas .text-small {
		font-size: 20px;
		line-height: 50px;
	}

	.section {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
</style>
